import { cloneDeep } from '@apollo/client/utilities';
import { css } from '@emotion/react';
import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import {
  CartItemRemoved,
  CartProduct,
  CartReferenceCartType,
} from '@lego/b2b-unicorn-data-access-layer';
import {
  useGetCart,
  useIsUpdatingCart,
  useOptimisticEmptyCart,
  useOptimisticUpdateCart,
} from '@lego/b2b-unicorn-data-access-layer/react';
import { useSelectedCustomer } from '@lego/b2b-unicorn-shared/components/UserContext';
import { commonBodyStyle, OrderPreview } from '@lego/b2b-unicorn-ui-checkout-flow';
import { media, ProductTableColumnType } from '@lego/b2b-unicorn-ui-constants';
import { CartMath, mapToMoney, sortAscending, sortDescending } from '@lego/b2b-unicorn-ui-utils';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { ReplenishmentCartRecommendations } from '../Recommendations';
import CartPreviewItemRow from './CartPreviewItemRow';
import CartPreviewItemTableHeader from './CartPreviewItemTableHeader';
import { EmptyReplenishmentCartPreview } from './EmptyReplenishmentCartPreview';

interface ICartPreview {
  onGoToCheckoutClick: () => void;
  onCartLoaded?: () => void;
}

const bodyStyle = css(commonBodyStyle, {
  [media.medium]: {
    marginBottom: 50,
    minHeight: 'calc(100vh - 466px)',
  },
});

const ReplenishmentCartPreview = ({ onGoToCheckoutClick, onCartLoaded }: ICartPreview) => {
  const selectedCustomer = useSelectedCustomer();
  const { popup_remove_all_content, popup_remove_all_header } = useLabels();

  const {
    data: cartData,
    error: cartError,
    loading: cartLoading,
    refetch: refetchCart,
  } = useGetCart(selectedCustomer.id, { cartType: CartReferenceCartType.Replenish }, true, {
    fetchPolicy: 'network-only',
  });

  const {
    mutate: updateCart,
    error: updateCartRawError,
    loading: updateCartLoading,
  } = useOptimisticUpdateCart(selectedCustomer.id, {
    cartType: CartReferenceCartType.Replenish,
  });
  const isCartUpdating = useIsUpdatingCart();
  const [emptyCart] = useOptimisticEmptyCart(selectedCustomer.id, {
    cartType: CartReferenceCartType.Replenish,
  });

  const updateCartError = useMemo(() => {
    if (updateCartRawError) {
      // In case of error, we want to refetch the cart to get the latest data
      refetchCart();
    }

    return !!updateCartRawError;
  }, [refetchCart, updateCartRawError]);

  const [removedItems, setRemovedItems] = useState<CartItemRemoved[] | null>(null);
  const [isCheckoutDisabled, setIsCheckoutDisabled] = useState(true);

  const minimumOrderValue = (cartData && cartData.getCart.minimumOrderValue) || mapToMoney(0, '');

  const handleItemUpdate = useCallback(
    (product: CartProduct, quantity: number) => {
      setIsCheckoutDisabled(true);
      updateCart({
        productOrMaterialId: product,
        quantity,
      });
    },
    [updateCart]
  );

  const handleItemRemove = useCallback(
    (product: CartProduct) => {
      updateCart({
        productOrMaterialId: product,
        quantity: 0,
      });
    },
    [updateCart]
  );

  const handleRemoveAll = () => {
    emptyCart();
  };

  // Sorting state
  const [activeSortingField, setActiveSortingField] = useState<ProductTableColumnType>();
  const [isAscending, setIsAscending] = useState(true);

  const items = useMemo(() => {
    if (!cartData) {
      return [];
    }

    if (
      cartData.getCart.removedItems &&
      cartData.getCart.removedItems.length > 0 &&
      removedItems === null
    ) {
      setRemovedItems(cartData.getCart.removedItems);
    }

    const clonedItems = cloneDeep(cartData.getCart.items);
    onCartLoaded && onCartLoaded();
    return isAscending
      ? sortAscending(clonedItems, activeSortingField)
      : sortDescending(clonedItems, activeSortingField);
  }, [cartData, activeSortingField, isAscending, removedItems, onCartLoaded]);

  useEffect(() => {
    if (updateCartLoading || !items || !cartData || isCartUpdating) {
      setIsCheckoutDisabled(true);
    } else {
      const totalPrice = CartMath.sumEstimatedNetInvoicedPrice(items).estimatedNetInvoicedPrice;
      setIsCheckoutDisabled(totalPrice > 0 ? totalPrice < minimumOrderValue.amount : true);
    }
  }, [cartData, isCartUpdating, items, minimumOrderValue, updateCartLoading]);

  return (
    <>
      <div css={bodyStyle}>
        <OrderPreview
          items={items}
          itemRowHeaderRender={
            <CartPreviewItemTableHeader
              activeSortingField={activeSortingField}
              setActiveSortingField={setActiveSortingField}
              isAscending={isAscending}
              setIsAscending={setIsAscending}
            />
          }
          itemRowRender={(cartItem) => (
            <CartPreviewItemRow
              key={cartItem.product.materialId}
              product={cartItem.product}
              quantity={cartItem.quantity}
              onUpdate={handleItemUpdate}
              onRemove={handleItemRemove}
              updateError={updateCartError}
            />
          )}
          emptyPreviewFeedbackRender={<EmptyReplenishmentCartPreview />}
          itemsLoading={cartLoading}
          orderPreviewError={!!cartError}
          isCheckoutDisabled={isCheckoutDisabled}
          onGoToCheckoutClick={onGoToCheckoutClick}
          onResetAction={handleRemoveAll}
          resetWarningText={{
            header: popup_remove_all_header,
            content: popup_remove_all_content,
          }}
          removedItems={removedItems}
          closeRemovedItemsPopupHandler={() => setRemovedItems([])}
          minimumOrderValue={minimumOrderValue}
        />
      </div>
      {!cartLoading && <ReplenishmentCartRecommendations />}
    </>
  );
};

export default ReplenishmentCartPreview;
