/* eslint-disable @typescript-eslint/ban-ts-comment */
import { ApolloError, NetworkStatus } from '@apollo/client';
import { css } from '@emotion/react';
import { useLabels } from '@lego/b2b-unicorn-bootstrap/components/BootstrapLabels';
import {
  CartReferenceCartType,
  CartReferenceInput,
  PaymentApolloError,
  PaymentErrorCodes,
  PaymentMethod,
} from '@lego/b2b-unicorn-data-access-layer';
import { useCreateOrder } from '@lego/b2b-unicorn-data-access-layer/react';
import { useSelectedCustomer } from '@lego/b2b-unicorn-shared/components/UserContext';
import { ExtractElementType } from '@lego/b2b-unicorn-shared/helpers';
import {
  AnimatedDots,
  Button,
  ContentSystemFeedback,
  GoBackLink,
  Spacer,
  SystemFeedbackType,
} from '@lego/b2b-unicorn-shared/ui';
import { InlineNotification, NotificationType } from '@lego/b2b-unicorn-ui-components';
import { ProductTableColumnType } from '@lego/b2b-unicorn-ui-constants';
import { sortAscending, sortDescending } from '@lego/b2b-unicorn-ui-utils';
import { formatISO9075, isAfter } from 'date-fns';
import { deepEqual } from 'fast-equals';
import Cookies from 'js-cookie';
import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { ContainerHeader } from '../../../../shop/components/ContainerHeader';
import { makeStateWithCookieHook, useCheckoutOperation } from '../../common/hooks';
import {
  actionsStyle,
  bigTextStyle,
  bodyStyle,
  borderTopStyle,
  contentStyle,
  formWrapperStyle,
  mobileCollapseStyle,
  pagePaddingDesktopStyle,
  pagePaddingStyle,
  submitInfoStyle,
  submitInfoWarningStyle,
  submitInfoWrapperStyle,
  submittingAnimationStyle,
  tableStyle,
} from '../../common/styles';
import { CheckoutStep, ICheckoutCookie, IDeliveryInfo } from '../../common/types';
import { convertDateStringToDate, convertDateStringToDateOrNull } from '../../common/utils';
import { createCheckoutCookieName } from '../../common/utils/createCheckoutCookieName';
import { ensureBusinessDay, todayPlusLeadTime } from '../../common/utils/leadTimeHelper.js';
import CheckoutSteps from '../CheckoutSteps';
import OrderRemovedItems from '../OrderRemovedItems';
import OrderSummary from '../OrderSummary';
import CheckoutItemRow from './CheckoutItemRow';
import CardPaymentSkeleton from './PaymentAndDelivery/CardPaymentSkeleton';
import CardPaymentBrickBank from './PaymentAndDelivery/components/CardPaymentBrickBank';
import DeliveryInformation from './PaymentAndDelivery/DeliveryInformation';
import OrderName from './PaymentAndDelivery/OrderName';
import SkeletonCheckoutPage from './SkeletonCheckoutPage';
import TableHeader from './TableHeader';

type OnPaymentInfoChange = React.ComponentProps<typeof CardPaymentBrickBank>['onPaymentInfoChange'];
type PaymentInfo = Parameters<OnPaymentInfoChange>[1];
type PaymentGraphQLError = ExtractElementType<PaymentApolloError['graphQLErrors']>;

interface ICheckoutPage {
  goBackHandler: () => void;
  orderCreationSuccessHandler: (orderNumber: number, orderType: CartReferenceCartType) => void;
  cookieDomain: string;
  cartReference: CartReferenceInput;
  onEmptyCart?: () => void;
  onCheckoutLoaded?: () => void;
}

const CheckoutPage: React.FC<ICheckoutPage> = ({
  goBackHandler,
  orderCreationSuccessHandler,
  cookieDomain,
  cartReference,
  onEmptyCart,
  onCheckoutLoaded,
}) => {
  const selectedCustomer = useSelectedCustomer();
  const {
    order_details,
    order_delivery_information,
    order_payment,
    order_edit,
    button_submit_order,
    button_submitting,
    content_system_feedback_error,
    snackbar_order_simulation_error,
    snackbar_creditcard_error,
    snackbar_submit_order_error,
    order_submit_disclaimer,
    order_blocked_disclaimer,
    payment_method_credits_placeholder,
    payment_method_add_card,
    payment_method_3Dsecure_error,
    loader_takes_longer,
  } = useLabels();
  const userPaysWithCard = selectedCustomer.paymentMethod === PaymentMethod.CreditCard;

  const [showForm, setShowForm] = useState(true); // Used for mobile
  const [showTable, setShowTable] = useState(true); // Used for mobile
  const [activeSortingField, setActiveSortingField] = useState<ProductTableColumnType>();
  const [isAscending, setIsAscending] = useState<boolean>(true);

  const {
    items,
    removedItems,
    deliveryDates,
    shipTos,
    shippingAddressId,
    setShippingAddressId,
    requestedDeliveryDate: requestedDeliveryDateFromHook,
    setRequestedDeliveryDate,
    simulationLoading,
    simulationError,
    simulationDetails,
    simulationNetworkStatus,
    checkoutLoading,
    checkoutError,
    minimumOrderValue,
    totalSimulationPrice,
    totalListPrice,
  } = useCheckoutOperation(selectedCustomer.id, {
    simulation: true,
    cartReference,
    removeObsoleteItems: cartReference.cartType === CartReferenceCartType.Replenish,
    cookieDomain,
  });

  const creatingOrder = useRef(false);
  const [
    createOrder,
    { error: submittingError, loading: isSubmittingOrder, data: createdOrderDetails },
  ] = useCreateOrder(selectedCustomer.id);

  useEffect(() => {
    if (!submittingError) {
      return;
    }

    if (submittingError instanceof ApolloError) {
      const typeCastedError = submittingError as PaymentApolloError;
      const knownPaymentError = typeCastedError.graphQLErrors
        .filter(
          (e) => e.extensions && Object.values(PaymentErrorCodes).indexOf(e.extensions.code) > -1
        )
        .pop();
      if (knownPaymentError) {
        setCreditCardError(knownPaymentError);
      }
    }

    creatingOrder.current = false;
  }, [submittingError]);

  const sortedItems = useMemo(() => {
    if (!items || creatingOrder.current) {
      return;
    }

    onCheckoutLoaded && onCheckoutLoaded();

    if (items.length === 0 && onEmptyCart) {
      onEmptyCart();
      return;
    }

    return isAscending
      ? // @ts-ignore - TypeScript fails to infer the type of the items array
        sortAscending(items, activeSortingField)
      : // @ts-ignore - TypeScript fails to infer the type of the items array
        sortDescending(items, activeSortingField);
  }, [activeSortingField, isAscending, items, onEmptyCart, onCheckoutLoaded]);

  const useStateWithCookie = useMemo(() => makeStateWithCookieHook(cookieDomain), [cookieDomain]);
  const checkoutCookieName = createCheckoutCookieName(selectedCustomer.id, cartReference);

  const [deliveryInfo, setDeliveryInfo] = useState<IDeliveryInfo | undefined>();
  const [creditCardLoading, setCreditCardLoading] = useState(userPaysWithCard ? true : false);
  const [creditCardError, setCreditCardError] = useState<ApolloError | PaymentGraphQLError>();
  const [brickBankDropinError, setBrickBankDropinError] = useState<string | Error>();
  const [paymentInfo, setPaymentInfo] = useState<PaymentInfo>(undefined);
  const [simulationWarning, setSimulationWarning] = useState<boolean>(false);
  const [showRemovedItems, setShowRemovedItems] = useState<boolean>(false);
  const prevRemovedItems = useRef(false);

  useEffect(() => {
    if (showRemovedItems) {
      return;
    }

    if (
      removedItems &&
      removedItems.length > 0 &&
      !deepEqual(removedItems, prevRemovedItems.current)
    ) {
      prevRemovedItems.current = JSON.parse(JSON.stringify(removedItems));
      setShowRemovedItems(true);
    }
  }, [removedItems, showRemovedItems]);

  useEffect(() => {
    if (simulationNetworkStatus === NetworkStatus.refetch || simulationLoading) {
      return;
    }

    if (
      simulationError ||
      (simulationDetails &&
        sortedItems &&
        sortedItems.length > 0 &&
        sortedItems[0] &&
        sortedItems[0].__typename === 'CartItemWithSimulationDetails' &&
        !sortedItems[0].product.price.netInvoicedPrice)
    ) {
      setSimulationWarning(true);
    } else {
      setSimulationWarning(false);
    }
  }, [simulationError, sortedItems, simulationNetworkStatus, simulationLoading, simulationDetails]);

  const [orderName, setOrderName] = useStateWithCookie<ICheckoutCookie, 'orderName'>(
    checkoutCookieName,
    'orderName'
  );

  const leadTime = useMemo(() => {
    if (!shipTos || !shippingAddressId) {
      return null;
    }

    const shipTo = shipTos.find((s) => s.id === shippingAddressId);

    return shipTo?.leadtimeInDays || 0;
  }, [shipTos, shippingAddressId]);

  const firstSelectableDate: Date | undefined = useMemo(() => {
    if (!deliveryDates || leadTime === null) {
      return;
    }

    const resultThatMayBeWeekend = deliveryDates.earliestDate || todayPlusLeadTime(leadTime);

    return ensureBusinessDay(resultThatMayBeWeekend);
  }, [deliveryDates, leadTime]);

  const lastSelectableDate: Date | undefined = useMemo(() => {
    if (!deliveryDates?.latestDate) {
      return;
    }

    return ensureBusinessDay(deliveryDates.latestDate, deliveryDates.latestDate);
  }, [deliveryDates]);

  useEffect(() => {
    if (
      (requestedDeliveryDateFromHook &&
        firstSelectableDate &&
        !isAfter(firstSelectableDate, convertDateStringToDate(requestedDeliveryDateFromHook))) ||
      !firstSelectableDate
    ) {
      return;
    }

    setRequestedDeliveryDate(
      formatISO9075(firstSelectableDate, {
        representation: 'date',
      })
    );
  }, [firstSelectableDate, requestedDeliveryDateFromHook, setRequestedDeliveryDate]);

  const handleShipToOnChange = (shippingId: number) => {
    if (shippingId === shippingAddressId) {
      return;
    }

    setShippingAddressId(shippingId);
  };

  const handleRequestedDeliveryDateChange = (date: Date | null) => {
    if (date && shippingAddressId) {
      setRequestedDeliveryDate(formatISO9075(date, { representation: 'date' }));
    }
  };

  const handleCloseRemovedItemsPopup = () => {
    if (sortedItems?.length === 0) {
      goBackHandler();
    } else {
      setShowRemovedItems(false);
    }
  };

  const handleValidDeliveryInfo = useCallback((info?: IDeliveryInfo) => {
    setDeliveryInfo(info);
  }, []);

  const hasValidShipTos = shipTos && shipTos.length > 0;

  const submitButtonIsDisabled =
    (userPaysWithCard && !paymentInfo) ||
    !deliveryInfo ||
    !hasValidShipTos ||
    simulationLoading ||
    checkoutLoading ||
    creditCardLoading ||
    isSubmittingOrder;

  useEffect(() => {
    if (createdOrderDetails) {
      Cookies.remove(checkoutCookieName, { domain: cookieDomain });
      orderCreationSuccessHandler(createdOrderDetails.orderNumber, cartReference.cartType);
    }
  }, [
    cartReference.cartType,
    checkoutCookieName,
    cookieDomain,
    createdOrderDetails,
    orderCreationSuccessHandler,
  ]);

  const handleOrderSubmitClick = () => {
    if (shippingAddressId && requestedDeliveryDateFromHook) {
      const requestedDeliveryDate = convertDateStringToDate(requestedDeliveryDateFromHook);
      creatingOrder.current = true;
      if (userPaysWithCard) {
        createOrder(
          shippingAddressId,
          requestedDeliveryDate,
          cartReference,
          orderName || '',
          paymentInfo
        );
      } else {
        createOrder(shippingAddressId, requestedDeliveryDate, cartReference, orderName || '');
      }
    }
  };

  const handlePaymentInfoChange: OnPaymentInfoChange = (isValid, paymentInfoChange) => {
    if (isValid && paymentInfoChange) {
      setPaymentInfo(paymentInfoChange);
    } else {
      setPaymentInfo(undefined);
    }
  };

  const handleCreditCardReady = () => {
    if (creditCardLoading) {
      setCreditCardLoading(false);
    }
  };

  const handleCreditCardError = (e: ApolloError) => {
    setCreditCardError(e);
    handleCreditCardReady();
  };

  // @TODO: We should figure out how to handle Dates from GraphQL in general, as we convert and/or format the string dates into Date objects all the time.
  const deliveryInformationRequestedDeliveryDate = useMemo(
    () => convertDateStringToDateOrNull(requestedDeliveryDateFromHook),
    [requestedDeliveryDateFromHook]
  );

  const renderDisclaimer = () => (
    <div css={submitInfoWrapperStyle}>
      {!simulationLoading && hasValidShipTos && (
        <div css={submitInfoStyle}>
          <span>{order_submit_disclaimer}</span>
        </div>
      )}
      {!hasValidShipTos && (
        <div css={submitInfoWarningStyle}>
          <span>{order_blocked_disclaimer}</span>
        </div>
      )}
    </div>
  );

  const renderCheckout = () => {
    return (
      shipTos &&
      items &&
      !checkoutError &&
      !brickBankDropinError && (
        <Fragment>
          {removedItems && showRemovedItems && (
            <OrderRemovedItems
              items={removedItems}
              closePopup={handleCloseRemovedItemsPopup}
            />
          )}
          <CheckoutSteps checkoutStep={CheckoutStep.CHECKOUT} />
          <OrderSummary
            simulationRunning={simulationLoading}
            cartItems={items}
            simulationDetails={simulationDetails}
            disclaimerRender={renderDisclaimer()}
            minimumOrderValue={minimumOrderValue}
            simulationFinalListPrice={totalListPrice}
            simulationFinalPrice={totalSimulationPrice}
          >
            <div css={actionsStyle}>
              <Button
                disabled={submitButtonIsDisabled}
                onClick={handleOrderSubmitClick}
                warning={!!submittingError}
              >
                {isSubmittingOrder ? (
                  <div css={submittingAnimationStyle}>
                    {button_submitting} <AnimatedDots />
                  </div>
                ) : (
                  button_submit_order
                )}
              </Button>
              {(creditCardError || submittingError) && (
                <InlineNotification
                  message={
                    creditCardError ? snackbar_creditcard_error : snackbar_submit_order_error
                  }
                  type={NotificationType.WARNING}
                  outlineIcon
                />
              )}
              {simulationWarning && (
                <InlineNotification
                  message={snackbar_order_simulation_error}
                  type={NotificationType.WARNING}
                  outlineIcon
                />
              )}
            </div>
          </OrderSummary>
          <div css={pagePaddingStyle}>
            <Spacer multiplier={2} />
            <GoBackLink
              onClick={goBackHandler}
              text={order_edit}
              withBorder
            />
            <div css={formWrapperStyle}>
              <div>
                <ContainerHeader
                  text={order_delivery_information}
                  open={showForm}
                  setOpen={setShowForm}
                />
                <div css={mobileCollapseStyle(showForm)}>
                  <DeliveryInformation
                    shipTos={shipTos}
                    shippingAddressId={shippingAddressId}
                    shipToOnChangeHandler={handleShipToOnChange}
                    minDate={firstSelectableDate}
                    maxDate={lastSelectableDate}
                    requestedDeliveryDate={deliveryInformationRequestedDeliveryDate}
                    onDateChange={handleRequestedDeliveryDateChange}
                    onValidInfoHandler={handleValidDeliveryInfo}
                    disabledFromOutside={isSubmittingOrder || simulationLoading}
                    excludedDateIntervals={deliveryDates?.rddNotAvailableDates || []}
                  />
                </div>
              </div>
              <div css={mobileCollapseStyle(showForm)}>
                <h4 css={bigTextStyle}>
                  {order_payment}
                  {userPaysWithCard &&
                    creditCardError &&
                    (creditCardError as PaymentGraphQLError).extensions && (
                      <small>
                        {(creditCardError as PaymentGraphQLError).extensions.code ===
                          'PAYMENT_REDIRECTSHOPPER' && payment_method_3Dsecure_error}
                        {(creditCardError as PaymentGraphQLError).extensions.code !==
                          'PAYMENT_REDIRECTSHOPPER' &&
                          (creditCardError as PaymentGraphQLError).extensions.paymentError
                            ?.refusalReason}
                      </small>
                    )}
                </h4>
                {userPaysWithCard ? (
                  <CardPaymentBrickBank
                    customerId={selectedCustomer.id}
                    locale={selectedCustomer.locale}
                    onPaymentInfoChange={handlePaymentInfoChange}
                    onError={handleCreditCardError}
                    onDropinError={setBrickBankDropinError}
                    onReady={handleCreditCardReady}
                    disabled={!hasValidShipTos || simulationLoading || isSubmittingOrder}
                    translations={{ payment_method_add_card }}
                    loaderElm={<CardPaymentSkeleton takesLongerText={loader_takes_longer} />}
                  />
                ) : (
                  <div css={contentStyle}>
                    <InlineNotification message={payment_method_credits_placeholder} />
                  </div>
                )}
              </div>
              <div css={mobileCollapseStyle(showForm)}>
                <OrderName
                  orderName={orderName}
                  setOrderName={setOrderName}
                />
              </div>
            </div>
          </div>
          <div css={pagePaddingDesktopStyle}>
            <div css={borderTopStyle}>
              <ContainerHeader
                text={order_details}
                open={showTable}
                setOpen={setShowTable}
              />
            </div>
            {sortedItems && sortedItems.length > 0 && (
              <>
                <table css={tableStyle(showTable)}>
                  <TableHeader
                    activeSortingField={activeSortingField}
                    setActiveSortingField={setActiveSortingField}
                    isAscending={isAscending}
                    setIsAscending={setIsAscending}
                  />
                  <tbody>
                    {sortedItems.map((item) => {
                      const expectedDeliveryDate =
                        item.__typename === 'CartItemWithSimulationDetails'
                          ? item.expectedDeliveryDate
                          : undefined;
                      const simulationPrice =
                        item.__typename === 'CartItemWithSimulationDetails'
                          ? item.product.price
                          : undefined;
                      /*
                       * For Launch orders we don't want expected delivery date to be compared to requested delivery date,
                       * as we do not want to highlight it as later than requested delivery date
                       */
                      const requestedDeliveryDate =
                        cartReference.cartType === CartReferenceCartType.Replenish
                          ? requestedDeliveryDateFromHook
                          : undefined;

                      return (
                        <CheckoutItemRow
                          key={item.product.materialId}
                          product={item.product}
                          quantity={item.quantity}
                          simulationRunning={simulationLoading}
                          requestedDeliveryDate={requestedDeliveryDate}
                          expectedDeliveryDate={expectedDeliveryDate}
                          simulationPricePerPiece={simulationPrice}
                          isLaunchProduct={cartReference.cartType === CartReferenceCartType.Launch}
                        />
                      );
                    })}
                  </tbody>
                </table>
                <Spacer />
              </>
            )}
            {(!sortedItems || sortedItems.length === 0) &&
              !simulationLoading &&
              !checkoutLoading && (
                <div css={css({ '> div': { marginTop: 0 } })}>
                  <ContentSystemFeedback
                    type={SystemFeedbackType.ERROR}
                    text={content_system_feedback_error}
                  >
                    <GoBackLink
                      onClick={goBackHandler}
                      text={order_edit}
                    />
                  </ContentSystemFeedback>
                </div>
              )}
          </div>
        </Fragment>
      )
    );
  };

  return (
    <div css={bodyStyle}>
      <>
        {checkoutLoading && !checkoutError && <SkeletonCheckoutPage />}
        {renderCheckout()}
        {(checkoutError || !!brickBankDropinError) && (
          <ContentSystemFeedback
            type={SystemFeedbackType.ERROR}
            text={content_system_feedback_error}
          >
            <GoBackLink
              onClick={goBackHandler}
              text={order_edit}
            />
          </ContentSystemFeedback>
        )}
      </>
    </div>
  );
};

export default CheckoutPage;
